import '../scss/app.scss'
import Tabby from 'tabbyjs'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);


if (process.env.NODE_ENV === 'development') {
    require('../index.html')
  }

const scrollTo = ({top,left,behavior, href}) => {
  window.scrollTo({
    top,
    left,
    behavior
  });

  
  window.history.pushState(null, '', href );
}

document.addEventListener('DOMContentLoaded', (e) => {

  const tabs = new Tabby('[data-tabs]')

  const body = document.body;
  const header = body.querySelector('header');
  const hamburgerTrigger = body.querySelector('.hamburger');
  const mobileDropdown = body.querySelector('.header__nav-menu');

  const dTrigger = document.getElementById('download-trigger');
  const dModal = document.getElementById('download-modal');
  const dCloseTriggers = document.querySelectorAll('.close-trigger');
  const dAccept = document.querySelector('.accept-trigger');

  gsap.to(body,{duration: 1, opacity: 1, delay: 0.2});

  if(window.innerWidth >= 1024) { 

    gsap.from(document.querySelector('.slide-up'),{duration:0.7, delay: 0.2, y: 100});
    gsap.from(document.querySelector('.slide-left'),{duration:0.7, delay: 0.2, x: 100});
  }
  

  const anchorTags = document.querySelectorAll('a[href^="#"]');

  // init mobile menu
  const close = function() {
    gsap.to(mobileDropdown,{duration: 0.7, opacity: 0, onComplete: () => {
      mobileDropdown.classList.remove('open');
      gsap.set(mobileDropdown,{visibility: 'hidden'});
    }});

    gsap.to([mobileDropdown], {duration: 0.7, backgroundColor: 'transparent'});

    gsap.to([hamburgerTrigger], {duration: 0.3, rotate: 0});
  }

  const open = function() {
    gsap.to(mobileDropdown,{duration: 0.7, opacity: 1, visibility: 'visible', onComplete: () => {
      mobileDropdown.classList.add('open');
    }});

    gsap.to([mobileDropdown], {duration: 0.7, backgroundColor: 'white'});
    gsap.to([hamburgerTrigger], {duration: 0.3, rotate: 90});
  }


  hamburgerTrigger.addEventListener('click',function(e) {
    e.preventDefault();

    let isOpen = mobileDropdown.classList.contains('open');

    isOpen ? close() : open();

  })

  if(window.innerWidth < 1024) {
    // console.log('menu');

    document.querySelectorAll('.header__nav-menu .nav-menu__item').forEach( el => {

      el.addEventListener('click',(e) => {
        e.preventDefault();
        close();

        console.dir(el.chi);
        let href = el.querySelector('a').attributes.href.value
        console.log(href,href.charAt(0) != '#');

        if(href && href.charAt(0) != "#") {

          window.open(href);

        }

      })

    })

  }
 

  for(var a of anchorTags) {
    // console.log(a);

    // init anchortag behavior
    a.addEventListener('click',function(e) {
      e.preventDefault();

      let href = this.attributes.href.value;

      let el = document.querySelector(href);

      el ? scrollTo({top: el.offsetTop - 100, left:0,behavior: 'smooth', href}) : console.error(`el ${href} not found`);
    
    })
  }


  // download modal
  const openDownloadModal = (e) => {
    e.preventDefault();
    gsap.to(dModal,{duration: 0.8, opacity: 1, visibility: 'visible', onComplete: () => {

      dModal.addEventListener('click',closeDownloadModal);

      dCloseTriggers.forEach(el => {
        el.addEventListener('click',closeDownloadModal);
      })

    }});
  }

  const closeDownloadModal = (e) => {

    // console.log(this);

    e ? e.preventDefault() : null;
    gsap.to(dModal,{duration: 0.8, opacity: 0, onComplete: () => {
      gsap.set(dModal,{visibility: 'hidden'});
    }});

    dModal.removeEventListener('click',closeDownloadModal);
    dCloseTriggers.forEach(el => {
      el.removeEventListener('click',closeDownloadModal);
    })
    
  }

  dAccept.addEventListener('click',function(e) {

    e.preventDefault();

    let href = this.attributes.href.value;

    if(href) {

      
      window.open(href);
      closeDownloadModal();

    }

  })

  dTrigger.addEventListener('click',openDownloadModal)

  dModal.querySelector('.modal').addEventListener('click',(e) => {
    e.stopPropagation();
  })



  // init header animation
  setTimeout(() => {
    ScrollTrigger.create({
      trigger: document.querySelector('.hero'),
      start: 'bottom ' + window.innerHeight/1.5 + 'px',
      end: 'bottom ' + window.innerHeight/1.5 + 'px',
      // markers: true,
      onEnter: () => {
        gsap.to(header,{duration: 0.7, backgroundColor: 'white', onComplete: () => {
          header.classList.add('shadow-xl');
        }});
      },
      onEnterBack: () => {
        gsap.to(header,{duration: 0.7, backgroundColor: 'transparent', onStart: () => {
          header.classList.remove('shadow-xl');
        }});
      }
    })
  },200);
  

})